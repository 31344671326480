import { Button, Grid, styled, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


function Ventas() {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getAllVentas();
        return;
    }, []);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90, flex: 1, },
        {
            field: 'fecha_hora',
            headerName: 'Fecha',
            flex: 4,
            width: 150,
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 150,
            flex: 3
        },
        {
            field: '',
            headerName: 'Detalle Venta',
            flex: 1,
            renderCell: (params: any) => (
                <Grid>
                    <Button color='primary' onClick={() => { detalleVenta(params); }} ><VisibilityIcon /></Button>
                </Grid>
            )
        }
    ];


    const columns2: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90, flex: 1, },
        {
            field: 'clave',
            headerName: 'Clave',
            flex: 2,
            width: 150,
        },
        {
            field: 'descripcion',
            headerName: 'Descripcion',
            width: 150,
            flex: 2
        },
        {
            field: 'modeloAuto',
            headerName: 'Modelo Auto',
            width: 150,
            flex: 3
        },
        {
            field: 'marcaProducto',
            headerName: 'Marca Producto',
            width: 150,
            flex: 3
        }
    ];

    const [rows, setRows] = useState<[]>([]);


    const getAllVentas = () => {
        let l: number = Number(sessionStorage.getItem("lugar"))
        axios({
            method: 'get',
            url: '/getAllVentas.php?lugar=' + l + '',
        })
            .then(function (response) {
                let stringvar = "\n\n\n\t"
                if (response.data === stringvar) {
                    console.log("response", response)
                } else {
                    setRows(response.data);
                }
            });
    }

    const [id, setId] = useState<String>("");
    const [fechaHora, setFecha] = useState<String>("");
    const [total, setTotal] = useState<String>("");
    const [Array, setArray] = useState<[]>([]);

    /*
{
"id": "12346121",
"idventa": "999",
"idarticulo": "12346121",
"cantidad": "1",
"precio": "50.00",
"descuento": "0.00",
"idcategoria": "1",
"codigo": null,
"nombre": "RETEN",
"precio_venta": "60.00",
"stock": "14",
"descripcion": "RUEDA TRASERA",
"condicion": "1",
"created_at": null,
"updated_at": "2022-10-13 17:22:47",
"modeloAuto": "BRONCO 91-96, CLUB WAGON 95-96, ECOLINE 91-96, F150 91-96, F250 91-96, F350 91-97, MUSTANG 95",
"clave": "9864-S",
"marcaProducto": "NATIONAL",
"marcaAuto": "FORD",
"seccion": "D",
"stockMinimo": "1",
"stockMaximo": "2"
}
*/

    const detalleVenta = (data: any) => {
        console.log(data.row);

        setId(data.row.id)
        setFecha(data.row.fecha_hora)
        setTotal(data.row.total)

        axios({
            method: 'get',
            url: '/getDetalleVentas.php?idVenta=' + data.row.id + '',
        })
            .then(function (response) {
                console.log("response", response.data);
                setArray(response.data);
                handleClickOpen();
            });



    }


    return (
        <Grid container>
            <Grid item xs={20} sx={{ height: 700, width: '100%', paddingTop: '5vh' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                />
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Detalle venta
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography>IDVENTA : {id}</Typography>
                        <Typography> FECHA Y HORA: {fechaHora}</Typography>
                        
                        <Grid sx={{width : '80vh', height: '30vh'}}>
                            <DataGrid
                                rows={Array}
                                columns={columns2}
                                pageSize={100}
                                rowsPerPageOptions={[4]}
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}
                            />
                          <Typography> TOTAL: {total}</Typography>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            Salir
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </Grid>
        </Grid>
    )
}



function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default Ventas;