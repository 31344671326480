import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridToolbar, GridToolbarQuickFilter, GridValueGetterParams } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import IconButton from '@mui/material/IconButton';
import Joi from 'joi';
var lodash = require('lodash');

//Carrito
//Cambiar cantidad del prod
//Modificar precio


//Ventas
//Filtro mostrarlo en la parte superior (Filter)
//Mandar alerta de que quedan pocos productos




interface Carrito {
    id: Number,
    nombre: String,
    precio: Number,
    cantidad: Number,
    clave: string
    marca: string
}


function Venta() {


    const columnsCarrito: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            type: 'string',
            flex: 1
        },
        {
            field: 'clave',
            headerName: 'Clave',
            flex: 3
        },
        {
            field: 'marca',
            headerName: 'Marca Producto',
            flex: 2
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            type: 'string',
            flex: 1
        },
        {
            field: 'precio',
            headerName: 'Precio',
            type: 'number',
            flex: 1
        },
        {
            field: 'cantidad',
            headerName: 'Cantidad',
            type: 'number',
            flex: 1
        },
        {
            field: '',
            headerName: 'Eliminar',
            flex: 1,
            renderCell: (params: any) => (
                <Grid>
                    <Button onClick={() => { eliminarCarrito(params) }}><DeleteForeverIcon /></Button>
                </Grid>
            )
        }
    ]



    const columns: GridColDef[] = [
        {
            field: 'clave',
            headerName: 'Clave',
            flex: 3,
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            flex: 2
        },
        {
            field: 'precio_venta',
            headerName: 'Precio',
            type: 'number',
            flex: 2
        },
        {
            field: 'modeloAuto',
            headerName: 'Modelo auto',
            flex: 5,
        },
        {
            field: 'marcaProducto',
            headerName: 'Marca Producto',
            flex: 2,
        },
        {
            field: 'marcaAuto',
            headerName: 'Marca Auto',
            flex: 3,
        },
        {
            field: 'stock',
            headerName: 'Cantidad',
            type: 'number',
            flex: 1
        },
        {
            field: 'seccion',
            headerName: 'Donde se Encuentra',
            flex: 1
        },
        {
            field: 'codigo',
            headerName: 'Codigo de Barras',
            flex: 1
        },
        {
            flex: 4,
            field: '',
            headerName: 'Agregar',
            renderCell: (params: any) => (
                <Grid>
                    <Button color='primary' onClick={() => { Ver(params); }} > <VisibilityIcon /> </Button>
                    <Button color='primary' onClick={() => { Agregar(params) }}><AddBoxIcon /></Button>
                </Grid>
            )
        }
    ];

    const [rows, setRows] = useState<Carrito[]>([]);
    const [total, setTotal] = useState<Number>(0);
    const [descuento, setDescuento] = useState<String>("0");
    const [status, setStatus] = useState<boolean>(true);
    const [lugar, setLugar] = useState<number>(1);
    const [marca, setMarca] = useState<String>("");
    const [buscarGlobal, setbuscarGlobal] = useState<String>("");

    const [statusButton, setstatusButton] = useState<boolean>(false);
    useEffect(() => {
        console.log("sessionStorage.getItem()", sessionStorage.getItem("lugar"));
        getAllProductos();
        return;
    }, []);

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };


    const Agregar = (data: any) => {
        console.log(data);
        const data3: Carrito = { id: data.row.id, nombre: data.row.nombre, precio: data.row.precio_venta, cantidad: 1, clave: data.row.clave, marca: data.row.marcaProducto }

        setRows([...rows, data3]);
        let preTotal: Number[] = []

        // eslint-disable-next-line array-callback-return
        rows.map((d) => {
            preTotal.push(d.precio)
        })

        const initialValue = 0;
        const sumWithInitial = preTotal.reduce(
            (accumulator, currentValue) => Number(accumulator) + Number(currentValue),
            initialValue
        );
        const finalPrecio = Number(sumWithInitial) - Number(descuento);

        console.log("preTotal", finalPrecio)
        setTotal(finalPrecio);
    }

    const eliminarCarrito = (data: any) => {
        // rows.filter((d) => {
        //     return d.id !== data.row.id
        // });
        console.log(data);
        const resultado = rows.filter((d) => { return d.id !== data.row.id });
        console.log(resultado);
        setRows(resultado);
    }

    const validaTotal = () => {
        let preTotal: Number[] = []
        rows.map((d) => {
            console.log(d.precio)
            preTotal.push(d.precio)
        })

        const initialValue = 0;
        const sumWithInitial = preTotal.reduce(
            (accumulator, currentValue) => Number(accumulator) + Number(currentValue),
            initialValue
        );

        const finalPrecio = Number(sumWithInitial) - Number(descuento);
        if (finalPrecio <= 0) {
            setstatusButton(true);
        } else {
            setstatusButton(false);
        }
        console.log(finalPrecio);
        setTotal(finalPrecio);
    }
    const [productos, setProductos] = useState<[]>([]);

    const [clave, setClave] = useState<String>("");

    const [nombre, setNombre] = useState<String>("");

    const [modeloAuto, setModeloAuto] = useState<String>("");

    const [codigo, setCodigo] = useState<String>("");



    const getAllProductos = () => {
        let l: number = Number(sessionStorage.getItem("lugar"))
        setLugar(l)
        console.log("l", l);
        axios({
            method: 'get',
            url: '/getAllProductos.php?lugar=' + l + '',
        })
            .then(function (response) {
                let stringvar = "\n\n\n\t"
                if (response.data === stringvar) {
                    console.log("response", response)
                } else {
                    setProductos(response.data);
                }
            });
    }

    const getProductos = () => {
        let l: number = Number(sessionStorage.getItem("lugar"))
        setLugar(l)
        axios({
            method: 'get',
            url: '/getProductos?clave=' + clave + '&nombre=' + nombre + '&modeloAuto=' + modeloAuto + '&codigo=' + codigo + '&marca=' + marca + '&lugar=' + l,
        })
            .then(function (response) {
                let stringvar = '\n\n\n\t'
                console.log("response:", response.data);
                if (response.data === stringvar) {
                    console.log("response", response)
                } else {
                    setProductos(response.data);
                }
            });


    }

    const getProductosGlobal = (text: any) => {
        let l: number = Number(sessionStorage.getItem("lugar"))
        setLugar(l)
        axios({
            method: 'get',
            url: '/searchAvanced?Texto=' + valueSearch + '&lugar=' + l,
        })
            .then(function (response) {
                let stringvar = '\n\n\n\t'
                console.log("response:", response.data);
                if (response.data === stringvar) {
                    console.log("response", response)
                } else {
                    setProductos(response.data);
                }
            });
    }

    const hadleChangeNombre = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setNombre(value);
        setStatus(false)
    }


    const hadleChangeCodigoBarras = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setCodigo(value);
        setStatus(false)
    }

    const hadleChangeMarca = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setMarca(value);
        setStatus(false)
    }

    const hadleChangeGlobalMarca = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setbuscarGlobal(value);
        setStatus(false)
    }




    const hadleChangeDescuento = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setDescuento(value);
    }

    const hadleChangeClave = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setClave(value);
        setStatus(false)
    }

    const hadleChangeModeloAuto = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setModeloAuto(value);
        setStatus(false)
    }

    const vender = async (carritoVenta: any) => {
        let l: number = Number(sessionStorage.getItem("lugar"))
        setLugar(l)
        console.log(l)
        await validaTotal();
        axios.post('/postCarrito.php?lugar=' + l, {
            carritoVenta,
            "total": total,
            "descuento": Number(descuento),
        }).then(function (response) {
            console.log(response);
            getAllProductos();
        });
        handleClose();
        setRows([]);
        console.log("carritoVenta", carritoVenta);

    }

    //Update
    const [idPreUpdate, setIdPreUpdate] = useState<String>("");
    const [codigPreUpdate, setCodigoPreUpdate] = useState<String>("");
    const [nombrePreUpdate, setnombrePreUpdate] = useState<string>("")
    const [precioPreUpdate, setprecioPreUpdate] = useState<string>("")
    const [modeloAutoPreUpdate, setmodeloAutoPreUpdate] = useState<string>("")
    const [MarcaAutoPreUpdate, setMarcaAutoPreUpdate] = useState<string>("")
    const [MarcaProductoPreUpdate, setMarcaProductoPreUpdate] = useState<string>("")
    const [cantidadPreUpdate, setcantidadPreUpdate] = useState<string>("")
    const [dondeseencuentraPreUpdate, setdondeseencuentraPreUpdate] = useState<string>("")
    const [stockMinimoPreUpdate, setstockMinimoPreUpdate] = useState<string>("");
    const [stockMaximoPreUpdate, setstockMaximoPreUpdate] = useState<string>("");
    const [clavePreUpdate, setclavePreUpdate] = useState<string>("");



    const Ver = (data: any) => {
        console.log(data)
        handleClickOpen2();
        console.log(data.row);
        setIdPreUpdate(data.row.id);
        setCodigoPreUpdate(data.row.codigo);
        setnombrePreUpdate(data.row.nombre);
        setprecioPreUpdate(data.row.precio_venta);
        setmodeloAutoPreUpdate(data.row.modeloAuto);
        setMarcaAutoPreUpdate(data.row.marcaAuto);
        setMarcaProductoPreUpdate(data.row.marcaProducto);
        setcantidadPreUpdate(data.row.stock);
        setdondeseencuentraPreUpdate(data.row.seccion);
        setstockMinimoPreUpdate(data.row.stockMinimo);
        setstockMaximoPreUpdate(data.row.stockMaximo);
        setclavePreUpdate(data.row.clave);
    }
    const [valueSearch, setvalueSearch] = useState<String>("");


    const buscarProductos = (textSearch: string) => {
        console.log("textSearch: ", textSearch);
        getProductosGlobal(textSearch);
    };



    const onChange = (data: React.ChangeEvent<HTMLInputElement>): void => {

        const { value } = data.target;
        console.log(value);

        setvalueSearch(value);
    }

    return (
        <Grid container sx={{ textAlign: 'center' }}>

            {/* <Grid item xs={4}>
                <TextField
                    id="value-search"
                    label="Ingresa Descripci&oacute;n"
                    value={valueSearch}
                    onChange={onChange}
                    onKeyUp={onSearch}
                    onKeyPress={(event) => event.key === 'Enter' && onSearch()}
                    InputProps={{
                        endAdornment: (
                            <IconButton data-testid="button-search" onClick={onSearch}>
                                <ImageSearchIcon />
                            </IconButton>
                        ),
                    }}
                    inputProps={{
                        'data-testid': 'input-search',
                    }}
                />
            </Grid> */}


            <Grid item xs={4}>
                <TextField label={"Clave"} value={clave} onChange={hadleChangeClave} onKeyUp={getProductos} />
            </Grid>

            <Grid item xs={4}>
                <TextField label={"Nombre"} value={nombre} onChange={hadleChangeNombre} onKeyUp={getProductos} />
            </Grid>

            <Grid item xs={4}>
                <TextField label={"Modelo Auto"} value={modeloAuto} onChange={hadleChangeModeloAuto} onKeyUp={getProductos} />
            </Grid>

            <Grid item xs={4}>
                <TextField label={"Marca"} value={marca} onChange={hadleChangeMarca} onKeyUp={getProductos} />
            </Grid>

            <Grid item xs={4}>
                <TextField label={"Codigo de Barras"} value={codigo} onChange={hadleChangeCodigoBarras} onKeyUp={getProductos} />
            </Grid>

            <Grid item xs={20}>
                <Button variant='contained' color='secondary' onClick={getProductos} disabled={status}>Buscar</Button>
            </Grid>
            <br></br>
            <br></br>
            <Grid item xs={20}>
                {/* <TextField label={"Buscar..."} value={buscarGlobal} onChange={hadleChangeGlobalMarca} onKeyUp={getProductosGlobal}/> */}
            </Grid>
            <Grid item xs={20} sx={{ height: 700, width: '100%', paddingTop: '5vh' }}>
                <DataGrid
                    getRowId={(row: any) => row.id}
                    rows={productos}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </Grid>
            <Grid item xs={20} sx={{ height: 700, width: '100%', paddingTop: '5vh' }}>
                <DataGrid
                    getRowId={(row: any) => row.id}
                    rows={rows}
                    columns={columnsCarrito}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </Grid>
            <Grid container>
                <Grid item xs={30}>
                    <TextField label={"Descuento"} value={descuento} onChange={hadleChangeDescuento} />
                </Grid>
                <Grid item xs={30}>
                    <Typography sx={{ color: '#000000' }}>Total : $ {total.toString()}</Typography>
                </Grid>
                <Grid item xs={20}>
                    <Button color='secondary' variant='contained' type='button' onClick={() => {
                        validaTotal();
                        handleClickOpen();
                    }}>Vender</Button>
                </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmar Venta</DialogTitle>
                <DialogContent>
                    <Typography>
                        Deseas Confirmar la venta por $ {total.toString()}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' disabled={statusButton} onClick={() => { vender(rows) }}>Vender</Button>
                    <Button color='primary' onClick={handleClose}>Cancelar</Button>
                </DialogActions>
            </Dialog>


            <Dialog open={open2} onClose={handleClose2}>
                <DialogTitle>Ficha tecnica</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <br></br>
                        <br></br>
                        <br></br>
                        <Grid item><TextField label={'codigo'} value={codigPreUpdate} /></Grid>
                        <Grid item><TextField label={'nombre'} value={nombrePreUpdate} /></Grid>
                        <Grid item><TextField label={'precio'} value={precioPreUpdate} /></Grid>
                        <Grid item><TextField label={'modeloAuto'} multiline maxRows={10} value={modeloAutoPreUpdate} /></Grid>
                        <Grid item><TextField label={'MarcaAuto'} value={MarcaAutoPreUpdate} /></Grid>
                        <Grid item><TextField label={'MarcaProducto'} value={MarcaProductoPreUpdate} /></Grid>
                        <Grid item><TextField label={'cantidad'} value={cantidadPreUpdate} /></Grid>
                        <Grid item><TextField label={'dondeseencuentra'} value={dondeseencuentraPreUpdate} /></Grid>
                        <Grid item><TextField label={'stockMinimo'} value={stockMinimoPreUpdate} /></Grid>
                        <Grid item><TextField label={'stockMaximo'} value={stockMaximoPreUpdate} /></Grid>
                        <Grid item><TextField label={'clave'} value={clavePreUpdate} /></Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose2}>Aceptar</Button>
                </DialogActions>
            </Dialog>

        </Grid>
    )
}

export default Venta;