import { Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import axios from 'axios';





function Usuarios() {

    useEffect(() => {
        getAllUusuarios();
        return;
    }, []);



    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'usuario',
            headerName: 'Usuario',
            flex: 1
        },
        {
            field: 'idrol',
            headerName: 'Rol',
            flex: 1,
            renderCell: (params: any) => (
                <Grid>
                    <Typography>{String(params.row.idrol)}</Typography>
                </Grid>
            )
        },
        {
            field: 'condicion',
            headerName: 'Status',
            flex: 1
        },

    ];

    const [rows, setRows] = useState<[]>([]);



    const getAllUusuarios = () => {

        axios({
            method: 'get',
            url: '/getAllUsers.php',
        })
            .then(function (response) {
                let stringvar = "\n\n\n\t"
                console.log("response", response)
                if (response.data === stringvar) {
                    console.log("responseIF", response.data)
                } else {
                    setRows(response.data);
                }
            });
    }

    return (
        <Grid container xs={20}>
            <Grid item xs={20} sx={{ height: 700, width: '100%', paddingTop: '5vh' }}>
                <DataGrid
                    // getRowId={(param) => {return param.row.id}}
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </Grid>
        </Grid>
    )
}

export default Usuarios;