import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { styled, useTheme, Theme, CSSObject, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Venta from '../venta/venta';
import Productos from '../productos/productos';
import ProductosxSurtir from '../productos/productosPorSurtir';
import Ventas from '../venta/Ventas';
import Tickets from '../venta/ticket';
import Graficas from '../venta/graficas';
import Usuarios from '../loggin/usuarios';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import axios from 'axios';
import Lottie from "lottie-react";
import car from "../../lottie/car.json";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        background: '#000000',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


function Header() {
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = useState(1);

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [locations, setlocations] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setlocations(event.target.value);
    };

    const loggin = () => {
        if (user !== "" && password !== "") {
            axios({
                method: 'get',
                url: '/login.php?usuario=' + user + '&contraseña=' + password,
            })
                .catch((e: any) => {
                    alert("credenciales invalidas");
                    console.log(e);
                })
                .then(function (response: any) {
                    if (response.data[0].usuario) {
                        console.log(response.data[0]);
                        setloginValidate(1);
                        let rolres = response.data[0].idrol;

                        setRol(1)
                        console.log(rol);
                        console.log(rolres);

                        console.log("locationsLoggin", locations)
                        sessionStorage.setItem("lugar", locations);
                        sessionStorage.setItem("user", user);
                        sessionStorage.setItem("rol", response.data[0].idrol);
                        console.log("rol", rol);
                        console.log("response.data[0].idrol", response.data[0].idrol);
                    } else {
                        alert("credenciales invalidas");
                    }
                });
        } else {
            alert("credenciales invalidas");
        }
    }

    const handleChangeUser = (data: any) => {
        setUser(data.target.value);
    }

    const handleChangePassword = (data: any) => {
        setPassword(data.target.value)
    }


    const [loginValidate, setloginValidate] = useState(0);
    const [rol, setRol] = useState(0);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleStatus = (data: any) => {
        console.log(data);
        setStatus(data);
    }

    const salir = () => {
        setloginValidate(0);
    }
    return (
        <Grid>
            {loginValidate === 1 && <Grid>
                {/* //TODOO : ccambiar a 1 el otro a 0 */}
                {Number(sessionStorage.getItem("rol")) === 1 &&
                    //TODOO : ccambiar a 1
                    <Grid item >
                        <Box sx={{ display: 'flex', background: '#FFFFFF' }}>
                            <CssBaseline />
                            <AppBar position="fixed" open={open} color='primary' elevation={10}>
                                <Toolbar>
                                    <IconButton
                                        color="secondary"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        sx={{
                                            marginRight: 5,
                                            ...(open && { display: 'none' }),
                                        }}
                                    >
                                        <MenuIcon color='secondary' />
                                    </IconButton>
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <Typography variant="h5" noWrap component="div">
                                                Cluth y Frenos Carrasco
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={1}>
                                            <PowerSettingsNewIcon color='secondary' onClick={salir} />
                                        </Grid>
                                    </Grid>
                                </Toolbar>

                            </AppBar>
                            <Grid >
                                <Drawer variant="permanent" open={open} >
                                    <DrawerHeader sx={{ background: '#000000', color: '#000000' }} >
                                        <IconButton color='secondary' onClick={handleDrawerClose}>
                                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                        </IconButton>
                                    </DrawerHeader>

                                    <List>

                                        <ListItem key={'Venta'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                onClick={() => { handleStatus(1) }}
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <ShoppingCartIcon color='secondary' />
                                                </ListItemIcon>
                                                <ListItemText primary={'Venta'} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>



                                        <ListItem key={'Productos'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                onClick={() => { handleStatus(2) }}
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <ShoppingBasketIcon color='secondary' />
                                                </ListItemIcon>
                                                <ListItemText primary={'Productos'} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>


                                        <ListItem key={'Por Surtir'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                onClick={() => { handleStatus(3) }}
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <AddShoppingCartIcon color='secondary' />
                                                </ListItemIcon>
                                                <ListItemText primary={'Por Surtir'} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>


                                        <ListItem key={'Ventas'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                onClick={() => { handleStatus(4) }}
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <StorefrontIcon color='secondary' />
                                                </ListItemIcon>
                                                <ListItemText primary={'Ventas'} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>


                                        <ListItem key={'Tickets'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                onClick={() => { handleStatus(5) }}
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <ConfirmationNumberIcon color='secondary' />
                                                </ListItemIcon>
                                                <ListItemText primary={'Tickets'} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>


                                        <ListItem key={'Graficas'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                onClick={() => { handleStatus(6) }}
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <BarChartIcon color='secondary' />
                                                </ListItemIcon>
                                                <ListItemText primary={'Graficas'} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>


                                        <ListItem key={'Usuarios'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                onClick={() => { handleStatus(7) }}
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <PeopleIcon color='secondary' />
                                                </ListItemIcon>
                                                <ListItemText primary={'Usuarios'} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>

                                </Drawer>
                            </Grid>
                            <Box component="main" sx={{ flexGrow: 1, paddingTop: '10vh', paddingLeft: '2vh' }} >
                                {status === 1 && <Grid item >
                                    <Venta />
                                </Grid>}
                                {status === 2 && <Grid item >
                                    <Productos />
                                </Grid>}
                                {status === 3 && <Grid item >
                                    <ProductosxSurtir />
                                </Grid>}
                                {status === 4 && <Grid item >
                                    <Ventas />
                                </Grid>}
                                {status === 5 && <Grid item >
                                    <Tickets />
                                </Grid>}
                                {status === 6 && <Grid item >
                                    <Graficas />
                                </Grid>}
                                {status === 7 && <Grid item >
                                    <Usuarios />
                                </Grid>}
                            </Box>
                        </Box>
                    </Grid>
                }
                {/* Rol 2 */}
                {Number(sessionStorage.getItem("rol")) === 2 &&
                    <Grid item >
                        <Box sx={{ display: 'flex', background: '#FFFFFF' }}>
                            <CssBaseline />
                            <AppBar position="fixed" open={open} color='primary'>
                                <Toolbar>
                                    <IconButton
                                        color="secondary"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        sx={{
                                            marginRight: 5,
                                            ...(open && { display: 'none' }),
                                        }}
                                    >
                                        <MenuIcon color='secondary' />
                                    </IconButton>
                                    <Typography variant="h6" noWrap component="div">
                                        Cluth y Frenos Carrasco
                                    </Typography>

                                    <Grid sx={{ paddingLeft: '30vh' }} >
                                        <Typography>Salir</Typography>
                                        <PowerSettingsNewIcon color='secondary' onClick={salir} />
                                    </Grid>
                                </Toolbar>

                            </AppBar>
                            <Drawer variant="permanent" open={open} sx={{ background: '#000000' }} >
                                <DrawerHeader sx={{ background: '#000000' }} >
                                    <IconButton color='secondary' onClick={handleDrawerClose}>
                                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                    </IconButton>
                                </DrawerHeader>
                                <Divider />
                                <List sx={{ background: '#000000', color: '#FFFFFF' }}>

                                    <ListItem key={'Venta'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(1) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <ShoppingCartIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Venta'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>



                                    <ListItem key={'Productos'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(2) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <ShoppingBasketIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Productos'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>


                                    <ListItem key={'Por Surtir'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(3) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <AddShoppingCartIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Por Surtir'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>


                                    <ListItem key={'Ventas'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(4) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <StorefrontIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Ventas'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>


                                    <ListItem key={'Tickets'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(5) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <ConfirmationNumberIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Tickets'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>


                                    <ListItem key={'Graficas'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(6) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <BarChartIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Graficas'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>


                                    <ListItem key={'Usuarios'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(7) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <PeopleIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Usuarios'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Drawer>
                            <Box component="main" sx={{ flexGrow: 1, paddingTop: '10vh', paddingLeft: '2vh' }}>
                                {status === 1 && <Grid item >
                                    <Venta />
                                </Grid>}
                                {status === 5 && <Grid item >
                                    <Tickets />
                                </Grid>}
                            </Box>
                        </Box>
                    </Grid>
                }
                {/* Rol 3 */}
                {Number(sessionStorage.getItem("rol")) === 3 && <Grid item >
                    <Grid item >
                        <Box sx={{ display: 'flex', background: '#FFFFFF' }}>
                            <CssBaseline />
                            <AppBar position="fixed" open={open} color='primary'>
                                <Toolbar>
                                    <IconButton
                                        color="secondary"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        sx={{
                                            marginRight: 5,
                                            ...(open && { display: 'none' }),
                                        }}
                                    >
                                        <MenuIcon color='secondary' />
                                    </IconButton>
                                    <Typography variant="h6" noWrap component="div">
                                        Cluth y Frenos Carrasco
                                    </Typography>

                                    <Grid sx={{ paddingLeft: '30vh' }} >
                                        <Typography>Salir</Typography>
                                        <PowerSettingsNewIcon color='secondary' onClick={salir} />
                                    </Grid>
                                </Toolbar>

                            </AppBar>
                            <Drawer variant="permanent" open={open} sx={{ background: '#000000' }} >
                                <DrawerHeader sx={{ background: '#000000' }} >
                                    <IconButton color='secondary' onClick={handleDrawerClose}>
                                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                    </IconButton>
                                </DrawerHeader>
                                <Divider />
                                <List sx={{ background: '#000000', color: '#000000' }}>

                                    <ListItem key={'Venta'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(1) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <ShoppingCartIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Venta'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>



                                    <ListItem key={'Productos'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(2) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <ShoppingBasketIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Productos'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>


                                    <ListItem key={'Por Surtir'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(3) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <AddShoppingCartIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Por Surtir'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>


                                    <ListItem key={'Ventas'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(4) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <StorefrontIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Ventas'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>


                                    <ListItem key={'Tickets'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(5) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <ConfirmationNumberIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Tickets'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>


                                    <ListItem key={'Graficas'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(6) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <BarChartIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Graficas'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>


                                    <ListItem key={'Usuarios'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            onClick={() => { handleStatus(7) }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <PeopleIcon color='secondary' />
                                            </ListItemIcon>
                                            <ListItemText primary={'Usuarios'} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Drawer>
                            <Box component="main" sx={{ flexGrow: 1, paddingTop: '10vh', paddingLeft: '2vh' }}>
                                {status === 2 && <Grid item >
                                    <Productos />
                                </Grid>}
                                {status === 3 && <Grid item >
                                    <ProductosxSurtir />
                                </Grid>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>}
            </Grid>}
            {/* regresar  0 */}
            {loginValidate === 0 && <Grid item >
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ padding: '1vh' }}>
                    <Grid item xs={5} sx={{ width: '10%' }}>
                        <Lottie animationData={car} loop={true} />
                    </Grid>

                    <Grid item xs={20} sx={{ width: '100%' }}>
                        <Grid item xs={20} sx={{ paddingBottom: '2vw' }}>
                            <TextField type='text' label={"Usuario"} value={user} onChange={handleChangeUser} />
                        </Grid>
                        <Grid item xs={20} sx={{ width: '100%', paddingBottom: '2vw' }}>
                            <TextField type='password' label={"Contraseña"} value={password} onChange={handleChangePassword} />
                        </Grid>
                        <Grid item xs={20} sx={{ paddingBottom: '2vw' }}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-autowidth-label">¿Donde te encuentras?</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    value={locations}
                                    onChange={handleChange}
                                    autoWidth
                                    label="¿Donde te encuentras?"
                                >
                                    <MenuItem selected value={1}>Tepe</MenuItem>
                                    <MenuItem value={2}>Ozumba</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={20}>
                            <Button variant='contained' onClick={loggin} fullWidth>Entrar</Button>
                        </Grid>
                    </Grid>



                </Grid>
            </Grid>}
        </Grid>
    )
}


export default Header;