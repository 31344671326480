import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';



const columns: GridColDef[] = [
    {
        field: 'clave',
        headerName: 'Clave',
        flex: 3
    },
    {
        field: 'nombre',
        headerName: 'Nombre',
        flex: 1
    },
    {
        field: 'precio_venta',
        headerName: 'Precio',
        type: 'number',
    },
    {
        field: 'modeloAuto',
        headerName: 'Modelo auto',
        flex: 4
    },
    {
        field: 'marcaProducto',
        headerName: 'Marca Producto',
        flex: 2
    },
    {
        field: 'marcaAuto',
        headerName: 'Marca Auto',
        flex: 3
    },
    {
        field: 'stock',
        headerName: 'Cantidad',
        type: 'number',
        flex: 1
    }
];


function Producto() {

    useEffect(() => {
        getAllProductos();
        return;
    }, []);

    const [productos, setProductos] = useState<[]>([]);

    const [nombre, setNombre] = useState<String>("");

    const [modeloAuto, setModeloAuto] = useState<String>("");

    const [codigo, setCodigo] = useState<String>("");

    const [marca, setMarca] = useState<String>("");

    const [clave, setClave] = useState<String>("");

    const getAllProductos = () => {
        let l: number = Number(sessionStorage.getItem("lugar"));
        console.log("l", l);

        axios({
            method: 'get',
            url: '/getAllProductosxSurtir.php?lugar=' + l + '',
        })
            .then(function (response) {
                let stringvar = "\n\n\n\t"
                if (response.data === stringvar) {
                    console.log("response", response)
                } else {
                    setProductos(response.data);
                }
            });
    }


    const getProductos = () => {
        let l: number = Number(sessionStorage.getItem("lugar"))
        axios({
            method: 'get',
            url: '/getProductosxSurtir?clave=' + clave + '&nombre=' + nombre + '&modeloAuto=' + modeloAuto + '&codigo=' + codigo + '&marca=' + marca + '&lugar=' + l,
        })
            .then(function (response) {
                let stringvar = '\n\n\n\t'
                console.log("response:", response.data);
                if (response.data === stringvar) {
                    console.log("response", response)
                } else {
                    setProductos(response.data);
                }
            });


    }


    const hadleChangeClave = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setClave(value);
    }

    const hadleChangeNombre = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setNombre(value);
    }


    const hadleChangeCodigoBarras = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setCodigo(value);
    }

    const hadleChangeModeloAuto = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setModeloAuto(value);
    }

    const hadleChangeMarca = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setMarca(value);
    }


    return (
        <Grid container>
            <Grid item xs={20}>
            </Grid>
            <Grid item xs={4} >
                <TextField label="Clave" value={clave} onChange={hadleChangeClave} />
            </Grid>
            <Grid item xs={4}>
                <TextField label={"Codigo de Barras"} value={codigo} onChange={hadleChangeCodigoBarras} />
            </Grid>
            <Grid item xs={4}>
                <TextField label={"Busqueda X Descripcion"} value={nombre} onChange={hadleChangeNombre} />
            </Grid>
            <Grid item xs={4}>
                <TextField label={"Busqueda X Modelo Auto"} value={modeloAuto} onChange={hadleChangeModeloAuto} />
            </Grid>

            <Grid item xs={4}>
                <TextField label={"Marca"} value={marca} onChange={hadleChangeMarca} onKeyUp={getProductos} />
            </Grid>
            <Grid item xs={20}>
                <Button onClick={getProductos} color={'primary'} variant={'contained'}>Buscar</Button>
            </Grid>
            <Grid item xs={20} sx={{ height: 700, width: '100%', paddingTop: '5vh' }}>
                <DataGrid
                    getRowId={(row: any) => row.id}
                    rows={productos}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </Grid>
        </Grid>
    )
}

export default Producto;