import { Grid } from '@mui/material';
import React from 'react';
import './App.css';
import Header from './pages/components/header';
import Loggin from './pages/loggin/loggin';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FF0000'
    }
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />} />
        {/* <Route
          path="/cluthyfrenos"
          element={
            //WARNING: Agregar para proteger la ruta
            //<RequireAuth>
            <Loggin/>
            //</RequireAuth>
          }
        /> */}
      </Routes>
    </BrowserRouter>


      </ThemeProvider>  
      ); 
}


function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  //const userState = useAppSelector(userInfo);
  //console.log('userstate', userState[0].token);

  if (true) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
