import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


function Producto() {

    useEffect(() => {
        getAllProductos();
        return;
    }, []);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const [valida, setValida] = useState<number>(1);

    const [productos, setProductos] = useState<[]>([]);

    const [nombre, setNombre] = useState<String>("");

    const [modeloAuto, setModeloAuto] = useState<String>("");
    const [lugar, setLugar] = useState<number>(1);
    const [codigo, setCodigo] = useState<String>("");
    const [clave, setClave] = useState<String>("");
    const [marca, setMarca] = useState<String>("");

    //insert
    const [codigInsert, setCodigoInsert] = useState<String>("");
    const [conversionesInsert, setconversionesInsert] = useState<String>("");
    const [nombreInsert, setnombreInsert] = useState<string>("")
    const [precioInsert, setprecioInsert] = useState<string>("")
    const [modeloAutoInsert, setmodeloAutoInsert] = useState<string>("")
    const [MarcaAutoInsert, setMarcaAutoInsert] = useState<string>("")
    const [MarcaProductoInsert, setMarcaProductoInsert] = useState<string>("")
    const [cantidadInsert, setcantidadInsert] = useState<string>("")
    const [dondeseencuentraInsert, setdondeseencuentraInsert] = useState<string>("")
    const [stockMinimoInsert, setstockMinimoInsert] = useState<string>("");
    const [stockMaximonsert, setstockMaximoInsert] = useState<string>("");
    const [claveInsert, setclaveInsert] = useState<string>("");


    //Update
    const [idPreUpdate, setIdPreUpdate] = useState<String>("");
    const [codigPreUpdate, setCodigoPreUpdate] = useState<String>("");
    const [nombrePreUpdate, setnombrePreUpdate] = useState<string>("")
    const [precioPreUpdate, setprecioPreUpdate] = useState<string>("")
    const [modeloAutoPreUpdate, setmodeloAutoPreUpdate] = useState<string>("")
    const [MarcaAutoPreUpdate, setMarcaAutoPreUpdate] = useState<string>("")
    const [MarcaProductoPreUpdate, setMarcaProductoPreUpdate] = useState<string>("")
    const [cantidadPreUpdate, setcantidadPreUpdate] = useState<string>("")
    const [dondeseencuentraPreUpdate, setdondeseencuentraPreUpdate] = useState<string>("")
    const [stockMinimoPreUpdate, setstockMinimoPreUpdate] = useState<string>("");
    const [stockMaximoPreUpdate, setstockMaximoPreUpdate] = useState<string>("");
    const [clavePreUpdate, setclavePreUpdate] = useState<string>("");
    const [converionesPreUpdate, setconverionesPreUpdate] = useState<string>("");



    const columns: GridColDef[] = [
        {
            field: 'clave',
            headerName: 'Clave',
            flex: 3
        },
        {
            field: 'conversiones',
            headerName: 'Conversiones',
            flex: 1
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            flex: 1
        },
        {
            field: 'precio_venta',
            headerName: 'Precio',
            type: 'number',
        },
        {
            field: 'modeloAuto',
            headerName: 'Modelo auto',
            flex: 5,
            resizable: true,
            renderCell: (params: any) => (
                <Grid>
                   <Typography variant='caption' letterSpacing={1} align='justify' paragraph whiteSpace="pre-line"> {String(params.row.modeloAuto)} </Typography>
                </Grid>
            )
        },
        {
            field: 'marcaProducto',
            headerName: 'Marca Producto',
            type: 'number',
            flex: 2
        },
        {
            field: 'marcaAuto',
            headerName: 'Marca Auto',
            type: 'number',
            flex: 3
        },
        {
            field: 'stock',
            headerName: 'Cantidad',
            type: 'number',
            flex: 1
        },
        {
            field: 'seccion',
            headerName: 'Donde se Encuentra',
            type: 'number',
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Estatus',
            flex: 1,
            renderCell: (params: any) => (
                <Grid>
                    {params.row.status >= 1 ? <Button color='success' onClick={() => { ActivarDesactivar(params); }} > <CheckCircleOutlineIcon /> </Button> : <Button color='secondary' onClick={() => { ActivarDesactivar(params); }} > <CancelIcon /> </Button>}
                </Grid>
            )
        },
        {
            field: '',
            headerName: 'Opciones',
            flex: 4,
            renderCell: (params: any) => (
                <Grid>
                    <Button color='primary' onClick={() => { Editar(params); }} > <EditIcon /> </Button>
                    <Button color='primary' onClick={() => { Copiar(params); }} > <ContentCopyIcon /> </Button>
                    <Button color='primary' onClick={() => { Elminar(params); }} > <DeleteForeverIcon /> </Button>
                </Grid>
            )
        }
    ];

    const Elminar = (data: any) => {
        let id = data.row.id
        console.log(data.row.id);
        axios({
            method: 'get',
            url: '/borrarProductos.php?idProducto=' + id,
        })
            .then(function (response) {
                getAllProductos();
            });
        //Eliminar producto agregar confirmacion 2 pasos
        alert("eliminado");
    }

    const Editar = (data: any) => {
        handleClickOpen();
        console.log(data.row);
        setValida(1);
        setIdPreUpdate(data.row.id);
        setCodigoPreUpdate(data.row.codigo);
        setnombrePreUpdate(data.row.nombre);
        setprecioPreUpdate(data.row.precio_venta);
        setmodeloAutoPreUpdate(data.row.modeloAuto);
        setMarcaAutoPreUpdate(data.row.marcaAuto);
        setMarcaProductoPreUpdate(data.row.marcaProducto);
        setcantidadPreUpdate(data.row.stock);
        setdondeseencuentraPreUpdate(data.row.seccion);
        setstockMinimoPreUpdate(data.row.stockMinimo);
        setstockMaximoPreUpdate(data.row.stockMaximo);
        setclavePreUpdate(data.row.clave);
        setconverionesPreUpdate(data.row.conversiones);
    }

    const Copiar = (data: any) => {
        handleClickOpen();
        console.log(data);
        setValida(0);
        setIdPreUpdate(data.row.id);
        setCodigoPreUpdate(data.row.codigo);
        setnombrePreUpdate(data.row.nombre);
        setprecioPreUpdate(data.row.precio_venta);
        setmodeloAutoPreUpdate(data.row.modeloAuto);
        setMarcaAutoPreUpdate(data.row.marcaAuto);
        setMarcaProductoPreUpdate(data.row.marcaProducto);
        setcantidadPreUpdate(data.row.stock);
        setdondeseencuentraPreUpdate(data.row.seccion);
        setstockMinimoPreUpdate(data.row.stockMinimo);
        setstockMaximoPreUpdate(data.row.stockMaximo);
        setclavePreUpdate(data.row.clave);
        setconverionesPreUpdate(data.row.conversiones);
    }

    const hadleChangeMarca = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setMarca(value);
        setStatus(false)
    }


    const hadleChangecodigPreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setCodigoPreUpdate(value);
    }
    const hadleChangenombrePreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setnombrePreUpdate(value);
    }
    const hadleChangeprecioPreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setprecioPreUpdate(value);
    }
    const hadleChangemodeloAutoPreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setmodeloAutoPreUpdate(value);
    }
    const hadleChangeMarcaAutoPreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setMarcaAutoPreUpdate(value);
    }
    const hadleChangeMarcaProductoPreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setMarcaProductoPreUpdate(value);
    }
    const hadleChangecantidadPreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setcantidadPreUpdate(value);
    }
    const hadleChangedondeseencuentraPreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setdondeseencuentraPreUpdate(value);
    }
    const hadleChangestockMinimoPreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setstockMinimoPreUpdate(value);
    }
    const hadleChangestockMaximoPreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setstockMaximoPreUpdate(value);
    }
    const hadleChangeclavePreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setclavePreUpdate(value);
    }

    const hadleChangeconversionPreUpdate = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
       setconverionesPreUpdate(value);
    }

    const [status, setStatus] = useState<boolean>(true);

    const Actualizar = () => {
        let l: number = Number(sessionStorage.getItem("lugar"))
        setLugar(l)
        console.log("l", l);
        console.log(idPreUpdate);
        let userS: string = String (sessionStorage.getItem("user"))
        axios({
            method: 'get',
            url: '/updateProductos.php?id=' + idPreUpdate + '&idcategoria=' + lugar + '&codigo=' + codigPreUpdate + '&nombre= ' + nombrePreUpdate + '&precioVenta=' + precioPreUpdate + '&stock=' + cantidadPreUpdate + '&descripcion=' + nombrePreUpdate + '&condicion=1&modelo=' + modeloAutoPreUpdate + '&clave=' + clavePreUpdate + '&marcaProducto=' + MarcaProductoPreUpdate + '&marcaAuto=' + MarcaAutoPreUpdate + '&seccion=' + dondeseencuentraPreUpdate + '&stockMinimo=' + stockMinimoPreUpdate + '&stockMaximo= ' + stockMaximoPreUpdate + '&user='+ userS + '&converiones='+ converionesPreUpdate,
        })
            .catch((e) => {
                console.log(e);
            })
            .then(function (response) {
                getAllProductos();
            });
    }

    const InsertarCopia = () => {
        let l: number = Number(sessionStorage.getItem("lugar"))
        setLugar(l ? l : 1)
        axios({
            method: 'get',
            url: '/postProductos.php?idcategoria=' + lugar + '&codigo=' + codigPreUpdate + '&nombre= ' + nombrePreUpdate + '&precioVenta=' + precioPreUpdate + '&stock=' + cantidadPreUpdate + '&descripcion=' + nombrePreUpdate + '&condicion=1&modelo=' + modeloAutoPreUpdate + '&clave=' + clavePreUpdate + '&marcaProducto=' + MarcaProductoPreUpdate + '&marcaAuto=' + MarcaAutoPreUpdate + '&seccion=' + dondeseencuentraPreUpdate + '&stockMinimo=' + stockMinimoPreUpdate + '&stockMaximo= ' + stockMaximoPreUpdate + '&converiones='+ converionesPreUpdate,
        })
            .then(function (response) {
                console.log(response);
                getAllProductos();
            });
    }




    const ActivarDesactivar = (data: any) => {
        let id = data.row.id
        let status = data.row.status
        console.log(data);
        axios({
            method: 'get',
            url: '/deactivar.php?idProducto=' + id + '&status=' + status + '',
        })
            .then(function (response) {
                getAllProductos();
            });
    }

    const getAllProductos = () => {
        let l: number = Number(sessionStorage.getItem("lugar"))
        setLugar(l)
        console.log("l", l);
        axios({
            method: 'get',
            url: '/getAllProductos.php?lugar=' + l + '',
        })
            .then(function (response) {
                let stringvar = "\n\n\n\t"
                if (response.data === stringvar) {
                    console.log("response", response)
                } else {
                    setProductos(response.data);
                }
            });
    }



    const getProductos = () => {
        let l:number = Number(sessionStorage.getItem("lugar"))
        setLugar(l)
        axios({
            method: 'get',
            url: '/getProductos?clave='+clave+'&nombre=' + nombre + '&modeloAuto=' + modeloAuto + '&codigo=' + codigo + '&marca='+ marca + '&lugar='+ l,
        })
            .then(function (response) {
                let stringvar = '\n\n\n\t'
                console.log("response:",response.data);
                if (response.data === stringvar) {
                    console.log("response", response)
                } else {
                    setProductos(response.data);
                }
            });


    }

    const hadleChangeNombre = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setNombre(value);
    }


    const hadleChangeCodigoBarras = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setCodigo(value);
    }

    const hadleChangeModeloAuto = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setModeloAuto(value);
    }


    const hadleChangeClave = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setClave(value);
        setStatus(false)
    }


    //insert

    const hadleChangeNombreInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setnombreInsert(value);
    }

    const hadleChangePrecioInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setprecioInsert(value);
    }

    const hadlemodeloAutoInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setmodeloAutoInsert(value);
    }

    const hadleMarcaAutoInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setMarcaAutoInsert(value);
    }

    const hadleMarcaProductoInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setMarcaProductoInsert(value);
    }

    const hadleChangeCantidadInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setcantidadInsert(value);
    }

    const hadleChangeDondeSeEncuentraInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setdondeseencuentraInsert(value);
    }

    const hadleChangestockMinimoInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setstockMinimoInsert(value);
    }

    const hadleChangestockMaximoInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setstockMaximoInsert(value);
    }

    const hadleChangeClaveInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setclaveInsert(value);
    }







    const hadleChangeCodigoBarrasInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setCodigoInsert(value);
    }

    const hadleChangeConversionesInser = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = data.target;
        setconversionesInsert(value);
    }

    
    const agregar = () => {
        let l: number = Number(sessionStorage.getItem("lugar"))
        setLugar(l ? l : 1)
        let userS: string = String (sessionStorage.getItem("user"))
        axios({
            method: 'get',
            url: '/postProductos.php?idcategoria=' + lugar + '&codigo=' + codigInsert + '&nombre= ' + nombreInsert + '&precioVenta=' + precioInsert + '&stock=' + cantidadInsert + '&descripcion=' + nombreInsert + '&condicion=1&modelo=' + modeloAutoInsert + '&clave=' + claveInsert + '&marcaProducto=' + MarcaProductoInsert + '&marcaAuto=' + MarcaAutoInsert + '&seccion=' + dondeseencuentraInsert + '&stockMinimo=' + stockMinimoInsert + '&stockMaximo= ' + stockMaximonsert + '&user='+ userS + "&conversiones="+conversionesInsert,
        })
            .then(function (response) {
                //Agregar dialog con los datos ddel producto a agregar para confirmar processo
                handleClose2();
                alert("agregado correctamente")
                console.log(response);
            });
    }

 



    return (
        <Grid container>
            <Grid item xs={20}>


                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Agregar Mas Productos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid item xs={2}>
                                <TextField label="Codigo de Barras" value={codigInsert} onChange={hadleChangeCodigoBarrasInser} />
                            </Grid>

                            <Grid item xs={2}>
                                <TextField label="Conversiones" value={conversionesInsert} onChange={hadleChangeConversionesInser} />
                            </Grid>
                            <Grid item xs={2} >
                                <TextField label="Nombre" value={nombreInsert} onChange={hadleChangeNombreInser} />
                            </Grid>
                            <Grid item xs={2} >
                                <TextField label="Clave" value={claveInsert} onChange={hadleChangeClaveInser} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField type={"number"} label="Precio" value={precioInsert} onChange={hadleChangePrecioInser} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField label="Modelo de auto" value={modeloAutoInsert} onChange={hadlemodeloAutoInser} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField label="Marca de auto" value={MarcaAutoInsert} onChange={hadleMarcaAutoInser} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField label="Marca de producto" value={MarcaProductoInsert} onChange={hadleMarcaProductoInser} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField type={"number"} label="Cantidad" value={cantidadInsert} onChange={hadleChangeCantidadInser} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField label="Donde se encuentra" value={dondeseencuentraInsert} onChange={hadleChangeDondeSeEncuentraInser} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField type={"number"}  label="Stock Minimo" value={stockMinimoInsert} onChange={hadleChangestockMinimoInser} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField type={"number"}  label="Stock Maximo" value={stockMaximonsert} onChange={hadleChangestockMaximoInser} />
                            </Grid>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Grid item xs={20}>
                                <Button size='large' type='button' variant='contained' onClick={handleClickOpen2}>Agregar</Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <br></br>



            </Grid>
            <Grid item xs={4}>
                <TextField label={"Clave"} value={clave} onChange={hadleChangeClave} onKeyUp={getProductos} />
            </Grid>

            <Grid item xs={4}>
                <TextField label={"Nombre"} value={nombre} onChange={hadleChangeNombre} onKeyUp={getProductos} />
            </Grid>

            <Grid item xs={4}>
                <TextField label={"Aplicacion"} value={modeloAuto} onChange={hadleChangeModeloAuto} onKeyUp={getProductos} />
            </Grid>

            <Grid item xs={4}>
                <TextField label={"Codigo de Barras"} value={codigo} onChange={hadleChangeCodigoBarras} onKeyUp={getProductos} />
            </Grid>

            <Grid item xs={4}>
                <TextField label={"Marca"} value={marca} onChange={hadleChangeMarca} onKeyUp={getProductos}/>
            </Grid>

            <Grid item xs={20}>
                <Button variant='contained' color='secondary' onClick={getProductos} disabled={status}>Buscar</Button>
            </Grid>

            <Grid item xs={20} sx={{ height: 700, width: '100%', paddingTop: '5vh' }}>
                <DataGrid
                    getRowId={(row: any) => row.id}
                    rows={productos}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                />
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{valida ? 'Editar' : 'Agregar'}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <br></br>
                        <Grid item><TextField label={'codigo'} onChange={hadleChangecodigPreUpdate} value={codigPreUpdate} /></Grid>
                        <Grid item><TextField label={'nombre'} onChange={hadleChangenombrePreUpdate} value={nombrePreUpdate} /></Grid>
                        <Grid item><TextField label={'precio'} onChange={hadleChangeprecioPreUpdate} value={precioPreUpdate} /></Grid>
                        <Grid item><TextField multiline maxRows={8} label={'modeloAuto'} onChange={hadleChangemodeloAutoPreUpdate} value={modeloAutoPreUpdate} /></Grid>
                        <Grid item><TextField multiline maxRows={8} label={'MarcaAuto'} onChange={hadleChangeMarcaAutoPreUpdate} value={MarcaAutoPreUpdate} /></Grid>
                        <Grid item><TextField label={'MarcaProducto'} onChange={hadleChangeMarcaProductoPreUpdate} value={MarcaProductoPreUpdate} /></Grid>
                        <Grid item><TextField label={'cantidad'} onChange={hadleChangecantidadPreUpdate} value={cantidadPreUpdate} /></Grid>
                        <Grid item><TextField label={'dondeseencuentra'} onChange={hadleChangedondeseencuentraPreUpdate} value={dondeseencuentraPreUpdate} /></Grid>
                        <Grid item><TextField label={'stockMinimo'} onChange={hadleChangestockMinimoPreUpdate} value={stockMinimoPreUpdate} /></Grid>
                        <Grid item><TextField label={'stockMaximo'} onChange={hadleChangestockMaximoPreUpdate} value={stockMaximoPreUpdate} /></Grid>
                        <Grid item><TextField label={'clave'} onChange={hadleChangeclavePreUpdate} value={clavePreUpdate} /></Grid>
                        <Grid item><TextField label={'conversiones'} onChange={hadleChangeconversionPreUpdate} value={converionesPreUpdate} /></Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {/* Botton que diferencia entre actualizar y agregar nuevo produco */}
                    {valida ? <Button onClick={Actualizar}>Editar</Button> : <Button onClick={InsertarCopia}>Agregar</Button>}
                </DialogActions>
            </Dialog>




            <Dialog
                open={open2}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ¿Confirmas el Producto?
                </DialogTitle>
                <DialogContent>
                   <Grid>
                        <Typography>Codigo de Barras: {codigInsert }</Typography>
                        <Typography>Nombre: {nombreInsert  }</Typography>
                        <Typography>Precio: {precioInsert  }</Typography>
                        <Typography>Cantidad: {cantidadInsert  }</Typography>
                        <Typography>Modelo Auto: {modeloAutoInsert  }</Typography>
                        <Typography>Clave: {claveInsert  }</Typography>
                        <Typography>Marca Producto: {MarcaProductoInsert  }</Typography>
                        <Typography>Marca auto: {MarcaAutoInsert  }</Typography>
                        <Typography>Donde se encuentra: {dondeseencuentraInsert  }</Typography>
                        <Typography>Stock Minimo: {stockMinimoInsert  }</Typography>
                        <Typography>Stock Maximo: {stockMaximonsert }</Typography>
                   </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={agregar} color={"primary"} variant={"contained"}>Agregar</Button>
                    <Button onClick={handleClose2} autoFocus color={"secondary"} variant={"contained"}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>


        </Grid>
    )
}

export default Producto;